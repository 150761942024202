import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';

const initialState = {
  reportSuccess: false,
  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};

export const getTransactionHistoryLedger = createEncryptedAsyncThunk(
  'reportSlice/getTransactionHistoryLedger',
  'Wallet/GetLedgerDetail',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.currentUser.userId,
      service_Id: 0,
      status: Array.isArray(params.transactype)
        ? params.transactype
        : [Number(params.transactype)],
      transaction_type: '',
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  },
);
export const GetTdsTransaction = createEncryptedAsyncThunk(
  'reportSlice/GetTdsTransaction',
  'Wallet/GetLedgerTDSDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.currentUser.userId,
      service_Id: 0,
      transaction_type: '',
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  },
);

export const getDMTTransectionHistory = createEncryptedAsyncThunk(
  'reportSlice/getDMTTransectionHistory',
  'account/GetDMTTransaction',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      dmt_transaction_userid: params.currentUser.userId,
      txn_id: '',
      // status: Array.isArray(params.transactype)
      //   ? params.transactype
      //   : [Number(params.transactype)],
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  },
);
export const getRechargeTransactionReciept = createEncryptedAsyncThunk(
  'reportSlice/getRechargeTransactionReciept',
  'account/GetBBPSReport',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      // bbps_userID: params.currentUser.userId,
      bbps_userid: params.currentUser.userId,
      txn_id: params.TransactionID,
      txn_type: '',
      txn_mode: '',
      customerNo: '',
      startDate: null,
      endDate: null,
    }),
  },
);
export const getRechargeTransactionHistory = createEncryptedAsyncThunk(
  'reportSlice/getRechargeTransactionHistory',
  'account/GetBBPSReport',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bbps_userID: params.currentUser.userId,
      txn_id: '',
      txn_type: params.selectOperator,
      txn_mode: '',
      customerNo: '',
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  },
);
export const getTopUpTransectionHistory = createEncryptedAsyncThunk(
  'reportSlice/getTopUpTransectionHistory',
  'RetailerReport/GetRetailerTopUpReport',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      fromdate: params.fromDate,
      todate: params.toDate,
    }),
  },
);
 
export const updateTransaction = createEncryptedAsyncThunk(
  'reportSlice/updateTransaction',
  'Transaction/GetFailedTrans',
  {
    prepareBody: (params) => ({
      walletId: 0,
      transId: params.closeTrans.Transaction_ID,
      created_By: params.currentUser.UserID,
      userID: params.currentUser.userGuid,
      mode: 3,
    }),
  },
);
export const getPaygTransectionHistory = createEncryptedAsyncThunk(
  'reportSlice/getDMTTransectionHistory',
  'account/CFPGGetAllPG',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      pG_transaction_userid: params.currentUser.userId,
      transactionid: '',
      startDate: params.fromDate,
      endDate: params.toDate,
      status:[0,1,2]
    }),
  },
);
export const reportSlice = createSlice({
  name: 'reportSlice',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    reportDeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    reportDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    reportDeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});

export const {
  creditAmountSuccess,
  creditAmountFailed,
  FetchSuccess,
  FetchFailed,
  reportDeleteSuccess,
  reportDeleteFailed,
  reportDeleteInitial,
} = reportSlice.actions;
export default reportSlice.reducer;
